.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  width: 35vw;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.575);
}

.cards-image {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s;
}

.card:hover .cards-image {
  opacity: 0;
}

.cards-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000;
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .cards-content {
  opacity: 1;
}

.cards-content h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 1000;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.cards-content p {
  text-align: left;
  width: 30rem;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.go-to {
  color: #3c3e3f;
  font-size: 20px;
  padding: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 80% */
  letter-spacing: 1px;

  text-transform: uppercase;
  cursor: pointer;
}
.card span{
  position: relative;
  left: 500px;
  top: -35px;
  
}


.arrow{
  font-size: 25px;
  cursor: pointer;
}
.go-to:hover{
  text-decoration: underline;
}
.three{
  width: 450px;
  z-index:999;
}
.three p{
  padding: 30px;
  z-index: 999;
}


/* Media queries for responsive design */

@media only screen and (max-width: 768px) {
  .card {
    width: 80vw; /* Change width for smaller screens */
  }

  .cards-container {
    justify-content: center; /* Center cards on smaller screens */
  }
  .cards-content p {
    font-size: 10px;
    padding: 10px;
    max-width: 250px;
  }
}
