@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

#slider1 {
  margin: 20px auto;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slides {
  overflow: hidden;
  animation-name: fade;
  animation-duration: 1s;
  display: none;

}

.main-images {
  width: 100vw;
  height: 650px !important;
  margin-bottom: 150px;
}

#dot {
  margin: 0 auto;
  text-align: center;
  display: none;
}

.dot {
  display: inline-block;
  border-radius: 50%;
  background: #d3d3d3;
  padding: 8px;
  margin: 10px 5px;
}

.active {
  background: black;
}

/* Mobile Phones (landscape) */
@media (min-width: 368px) and (max-width: 767px) {
  #slider {
    max-height: 100px; /* Adjust max-height for smaller screens */
    
  }
  
.main-images {
  width: 100%;
  height: 100%;
  margin-top: -200px;
  margin-bottom: -200px;
  object-fit: contain; /* Set object-fit to contain */
}
}

/* Tablets (portrait) */
@media (min-width: 768px) and (max-width: 991px) {
  #slider {
    max-height: 600px; /* Adjust max-height for tablets */
  }
}

/* Tablets (landscape) and Small Desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  #slider {
    max-height: 800px; /* Adjust max-height for small desktops */
  }
}

/* Medium Desktops and Large Desktops */
@media (min-width: 1200px) and (max-width: 1599px) {
  #slider {
    max-height: 1000px; /* Adjust max-height for medium and large desktops */
  }
}

/* Extra Large Desktops */
@media (min-width: 1600px) {
  #slider {
    max-height: 1200px; /* Adjust max-height for extra-large desktops */
  }
}
