.poster {
  background-image: url(../../Assets/Images/bg.png);
  height: 70vh;
  background-size: cover;
}
.poster2 {
  background-image: url(../../Assets/Images/bgimage.png);
  height: 70vh;
  position: relative;
  bottom: 180px;
  background-size: cover;
}
.tvc-poster {
  background-image: url(../../Assets/Images//tvs.bg.png);
  height: 70vh;
  background-size: cover;
}
.tvc-poster2 {
  background-image: url(../../Assets/Images/tvc2.png);
  height: 70vh;
  position: relative;
  bottom: 180px;
  background-size: cover;
}
.school-poster {
  background-image: url(../../Assets/Images/school1.png);
  height: 70vh;
  background-size: cover;
}
.school-poster2 {
  background-image: url(../../Assets/Images/school2.png);
  height: 70vh;
  position: relative;
  bottom: 180px;
  background-size: cover;
}
.center-poster {
  background-image: url(../../Assets/Images/center1.png);
  height: 70vh;
  background-size: cover;
}
.center-poster2 {
  background-image: url(../../Assets/Images/center2.png);
  height: 70vh;
  position: relative;
  bottom: 180px;
  background-size: cover;
}
.first-content,
.second-content {
  width: 1000px;
  /* border: 2px solid red; */
  height: 280px;
  position: relative;
  left: 280px;
  bottom: 150px;
  z-index: 999;
  padding: 20px;
  text-align: center;
  background: #fff;
}

.first-content p,
.second-content p {
  color: #111E47;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}

.third-container {
  display: flex;
  justify-content: center;
}

.images {
  width: 43vw;
  height: 367px;
  margin-bottom: 150px;
}

.slider {
  position: relative;
  margin-bottom: 50px;
  z-index: 999;
}

.description {
  width: 100%;
  padding: 20px;
}

.description h2 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.2px;
}

.description p {
  color: #3C3E3F;
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

/* Media Queries */

/* Small screens */
@media (max-width: 480px) {
  .first-content,
  .second-content {
    height: auto;
    bottom: 100px;
    left: 0;
    max-width: 100vw;
  }
  .third-container{
    display: block;
  }
  .images {
    height: 275px ;
    width: 100vw;
    position: relative;
    object-fit: contain;
  }.poster2{
    height: 31vh;
    position: relative;
    bottom: 20px;
    background-size: cover;
  }
  .poster{
    height: 30vh;
    background-size:contain;
    background-repeat: no-repeat;
  }
  .tvc-poster{
    height: 34vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tvc-poster2{
    height: 20vh;
    position: relative;
    bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster2{
    height: 19vh;
    position: relative;
    bottom: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .school-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;

  }
  .school-poster2{
    height: 22vh;
    position: relative;
    bottom: 14px;
    background-size: cover;
  }
  .p{
    margin-top: 130px;
  }
  
}

/* Medium screens */
@media (min-width: 481px) and (max-width: 768px) {
  /* Adjust styles for medium screens if needed */
}

/* Large screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .first-content,
  .second-content {
    height: auto;
    bottom: 100px;
    left: 0;
    max-width: 100vw;
  }
  .third-container{
    display: block;
  }
  .images {
    height: 275px ;
    width: 100vw;
    position: relative;
    object-fit: contain;
  }.poster2{
    height: 31vh;
    position: relative;
    bottom: 20px;
    background-size: cover;
  }
  .poster{
    height: 30vh;
    background-size:contain;
    background-repeat: no-repeat;
  }
  .tvc-poster{
    height: 34vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tvc-poster2{
    height: 20vh;
    position: relative;
    bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster2{
    height: 19vh;
    position: relative;
    bottom: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .school-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;

  }
  .school-poster2{
    height: 22vh;
    position: relative;
    bottom: 14px;
    background-size: cover;
  }
  .p{
    margin-top: 130px;
  }
}

/* Larger screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .first-content,
  .second-content {
    height: auto;
    bottom: 100px;
    left: 0;
    max-width: 100vw;
  }
  .third-container{
    display: block;
  }
  .images {
    height: 275px ;
    width: 100vw;
    position: relative;
    object-fit: contain;
  }.poster2{
    height: 31vh;
    position: relative;
    bottom: 20px;
    background-size: cover;
  }
  .poster{
    height: 30vh;
    background-size:contain;
    background-repeat: no-repeat;
  }
  .tvc-poster{
    height: 34vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tvc-poster2{
    height: 20vh;
    position: relative;
    bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .center-poster2{
    height: 19vh;
    position: relative;
    bottom: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .school-poster{
    height: 30vh;
    background-size: contain;
    background-repeat: no-repeat;

  }
  .school-poster2{
    height: 22vh;
    position: relative;
    bottom: 14px;
    background-size: cover;
  }
  .p{
    margin-top: 130px;
  }
}

/* Extra-large screens */
@media (min-width: 1201px) {
  /* Adjust styles for extra-large screens if needed */
}