@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");

* {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

nav {
  box-sizing: border-box;
  display: flex;
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Adjusted padding for smaller screens */
}

nav a {
  text-decoration: none;
  color: #233f96;
}

.logo {
  color: #233f96;
  font-size: 24px; /* Adjusted font size for smaller screens */
  font-weight: 600;
  display: flex;
  align-items: center; /* Centering logo content */
}

.logo img {
  width: 40px; /* Adjusted logo size for smaller screens */
  height: 40px;
  margin-right: 10px;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px; /* Adjusted margin for smaller screens */
  position: relative;
}

.donate a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px; /* Adjusted font size for smaller screens */
  font-weight: 500;
  padding: 10px 15px; /* Adjusted padding for smaller screens */
  letter-spacing: 1px;
  color: white;
  border-radius: 5px;
  background-color: #233f96;
  transition: all 0.3s ease;
}

nav ul li a {
  color: #233f96;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px; /* Adjusted font size for smaller screens */
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.menu-btn {
  color: white;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 350px;
  overflow:hidden;
  background-color: #233f96;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.dropdown-content li a:hover{
  background-color: white;
  color: black;
}

.dropdown-content li {
  width: 100%;
  padding: 10px;
  font-size: 14px; /* Adjusted font size for smaller screens */
}

.dropdown-content a {
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.menu-btn {
  display: none;
  cursor: pointer;
}

.menu-btn span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #233f96;
  margin: 5px 0;
  transition: 0.4s;
}

#menuToggle:checked + .menu-btn span:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

#menuToggle:checked + .menu-btn span:nth-child(2) {
  opacity: 0;
}

#menuToggle:checked + .menu-btn span:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Add this style to hide the checkbox */
#menuToggle {
  display: none;
}


/* Media Queries */

/* Small screens */
@media (max-width: 480px) {
  nav {
    padding: 0 10px; /* Adjusted padding for smaller screens */
  }

  .logo {
    font-size: 35px; /* Adjusted font size for smaller screens */
  }

  .logo img {
    width: 55px; /* Adjusted logo size for smaller screens */
    height: 55px;
    margin-right: 8px;
    margin-bottom: 10px;
  }

  nav ul li {
    margin: 0 10px; /* Adjusted margin for smaller screens */
  }

  .donate a {
    font-size: 12px; /* Adjusted font size for smaller screens */
    padding: 8px 12px; /* Adjusted padding for smaller screens */
  }

  nav {
    position: relative;
  }

  .menu-btn {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
  }

  ul {
    position: fixed;
    top: 80px;
    left: -100%;
    background: #11111176;
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
    z-index: 1;
  }

  #menuToggle:checked + ul {
    left: 0;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  nav ul li {
    margin: 10px 0;
  }
  ul.menu-list {
    position:absolute;
    top: 80px;
    left: -100%;
    background: white; /* Background color for the menu */
    height: 20vh;
    width: 100%;
    overflow-block:clip;
    border: 1px solid gray;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 999;
  }

  ul.menu-list.open {
    left: 0;
  }

  nav ul li {
    margin: 20px 0; /* Adjusted margin for better spacing */
    /* z-index: 999; */
    
  }

  nav ul li a {
    color:black; /* Text color for menu items */
    font-size: 18px; /* Adjusted font size for better readability */
    padding: 15px 0; /* Adjusted padding for better spacing */
    transition: color 0.3s ease; /* Smooth color transition */
    z-index: 999;
  }

  nav ul li a:hover {
    color: #ffd700; /* Change color on hover */
    z-index: 999;
  }
  .dropdown-content{
    left: -30px;
    height: 300px;
    overflow-y: auto;
    z-index: 999;
  }
  .dropdown-content li{
    border-bottom: 1px solid gray;
    z-index: 999;
    
  }
}

/* Medium screens */
@media (min-width: 481px) and (max-width: 768px) {
  /* Add specific styles for medium screens if needed */
}

/* Large screens */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Add specific styles for large screens if needed */
}

/* Larger screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Add specific styles for larger screens if needed */
}

/* Extra-large screens */
@media (min-width: 1201px) {
  /* Add specific styles for extra-large screens if needed */
}
