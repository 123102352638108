/* General Styles */
.footer-section {
  background: white;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #233f96;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #233f96;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
  font-size: 30px;
}

.footer-logo a {
  text-decoration: none;
  color: #233f96;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: gray;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.footer-widget-heading h3 {
  color: #233f96;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #233f96;
}

.footer-widget ul li {
  display: inline-block;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #233f96;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: white;
  border-radius: 10px;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.send {
  font-size: 30px;
  color: #233f96;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  border: none;
  background-color: transparent;
  padding: 9.3px 20px;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #233f96;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 20px;
  color: #878787;
}

.copyright-text p a {
  color: white;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.qr-code {
  width: 120px;
  position: relative;
  left: 200px;
  top: -20px;
  font-size: 14px;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: flex;
  animation: marquee 20s linear infinite; /* Adjust the duration as needed */
}

.marquee-content img {
  margin-right: 60px; /* Adjust the spacing between images */
  height: 50px;
}

.text-marquee {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  animation: text-marquee 40s linear infinite;
}

.text-marquee h6 {
  font-size: 15px;
  line-height: 2;
  text-align: center;
}

.heading-footer {
  text-align: center;
  margin: 20px;
}

.heading-footer p {
  font-size: 20px;
  margin-bottom: 20px;
}

.heading-footer h2 {
  color: #ff5e14;
}

/* Media Queries */

/* Small screens */
@media (max-width: 480px) {
  .marquee-content img {
    margin-right: 30px;
  }
  .text-marquee{
    display: block;
    text-align: left;
    animation: text-marquee 25s linear infinite;
  }
  .text-marquee h6 {
    font-size: 14px;
    text-align: left;
  }

  .footer-widget-heading h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
}

/* Medium screens */
@media (min-width: 481px) and (max-width: 768px) {
  .marquee-content img {
    margin-right: 40px;
  }

  .text-marquee h6 {
    font-size: 16px;
  }
}

/* Large screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .marquee-content img {
    margin-right: 50px;
  }

  .text-marquee h6 {
    font-size: 18px;
  }
}

/* Larger screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .marquee-content img {
    margin-right: 55px;
  }

  .text-marquee h6 {
    font-size: 19px;
  }
}

/* Extra-large screens */
@media (min-width: 1201px) {
  .marquee-content img {
    margin-right: 60px;
  }

  .text-marquee h6 {
    font-size: 20px;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes text-marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
