.main-container {
  padding: 20px;
  text-align: center;
}

.main-title {
  color: #f39473;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  line-height: 1.5;
}

.main-desc {
  color: #111e47;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  /* letter-spacing: 0.5px; */
  letter-spacing: 0;
  margin: 20px 0;
  text-align: justify;
}

.single-card {
  width: 90vw;
  overflow: hidden;
  position: relative;
  left: 5%;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.3s;
}





.single-card:hover {
  transform: scale(1);
}

.card-image {
  width: 90vw;
  height: auto;
  display: block;
  transition: filter 0.3s;
}

.card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.347);
  opacity: 0;
  transition: opacity 0.3s;
}

.single-card:hover .card-content {
  opacity: 1;
}

.card-content p {
  margin: 10px 0;
  font-size: 16px;
  color: black;
  background-color: rgba(255, 255, 255, 0.636);
  padding: 10px;
  position: relative;
  max-width: 300px;
  top: 100px;
  left: 70%;
  border-radius: 10px;
}

.card-content h2,
.card-content h3 {
  color: black;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.636);
  padding: 10px;
  max-width: 300px;
  position: relative;
  border-radius: 10px;
  top: 100px;
  left: 70%;
}

.card-content span {
  font-size: 14px;
  font-weight: 100;
  font-style: italic;
}

.card-content button {
  position: relative;
  border-radius: 8px;
  width: 150px;
  padding: 8px;
  top: 100px;
  left: 35%;
}

.card-content button:hover {
  background-color: #111e47;
  color: white;
}
.accordion-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
text-align: justify;
  overflow: hidden;
}
.arrow{
  font-size: 35px;
  /* text-align: right; */

}
.arrows{
  display: flex;
  justify-content: space-between;
}
.accordion-title {
  padding: 20px;
  margin: 0;
  text-align: left;
  font-size: 20px;
  cursor: pointer;
  background-color: #f39473;
  color: #fff;
}

.accordion-item.open .accordion-title {
  background-color: #111e47;
}
/* Mobile Phones (landscape) */
@media (min-width: 320px) and (max-width: 600px) {
  .accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .arrow{
    font-size: 30px;
    text-align: right;
  
  }
  .accordion-title {
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    background-color: #f39473;
    color: #fff;
  }
  
  .accordion-item.open .accordion-title {
    background-color: #111e47;
  }
  .card-content h2{
    color: black;
    font-size: 10px;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    max-width: 110px;
    position: relative;
    border-radius: 10px;
    top: 32px;
    left: 185px;
  }
  .card-content p{
    margin: 10px 0;
    font-size: 10px;
    color: black;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    position: relative;
    max-width: 300px;
    top: 24px;
    left: 50px;
    border-radius: 10px;
  }
  .card-content button{
    position: relative;
    border-radius: 8px;
    width: 81px;
    padding: 1px;
    top: -71px;
    left: 35%;
    font-size: 10px;
  }
}

/* Tablets (portrait) */
@media (min-width: 601px) and (max-width: 768px) {
  .accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .arrow{
    font-size: 30px;
    text-align: right;
  
  }
  .accordion-title {
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    background-color: #f39473;
    color: #fff;
  }
  
  .accordion-item.open .accordion-title {
    background-color: #111e47;
  }

}

/* Tablets (landscape) and Small Desktops */
@media (min-width: 769px) and (max-width: 991px) {
  .accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .arrow{
    font-size: 30px;
    text-align: right;
  
  }
  .accordion-title {
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    background-color: #f39473;
    color: #fff;
  }
  
  .accordion-item.open .accordion-title {
    background-color: #111e47;
  }
  .card-content h2{
    color: black;
    font-size: 10px;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    max-width: 110px;
    position: relative;
    border-radius: 10px;
    top: 32px;
    left: 185px;
  }
  .card-content p{
    margin: 10px 0;
    font-size: 10px;
    color: black;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    position: relative;
    max-width: 300px;
    top: 24px;
    left: 50px;
    border-radius: 10px;
  }
  .card-content button{
    position: relative;
    border-radius: 8px;
    width: 81px;
    padding: 1px;
    top: -71px;
    left: 35%;
    font-size: 10px;
  }
}

/* Medium Desktops and Large Desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  .arrow{
    font-size: 30px;
    text-align: right;
  
  }
  .accordion-title {
    padding: 5px;
    margin: 0;
    text-align: left;
    font-size: 20px;
    cursor: pointer;
    background-color: #f39473;
    color: #fff;
  }
  
  .accordion-item.open .accordion-title {
    background-color: #111e47;
  }
  .card-content h2{
    color: black;
    font-size: 10px;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    max-width: 110px;
    position: relative;
    border-radius: 10px;
    top: 32px;
    left: 185px;
  }
  .card-content p{
    margin: 10px 0;
    font-size: 10px;
    color: black;
    background-color: rgba(255, 255, 255, 0.636);
    padding: 10px;
    position: relative;
    max-width: 300px;
    top: 24px;
    left: 50px;
    border-radius: 10px;
  }
  .card-content button{
    position: relative;
    border-radius: 8px;
    width: 81px;
    padding: 1px;
    top: -71px;
    left: 35%;
    font-size: 10px;
  }
}

/* Extra Large Desktops */
@media (min-width: 1600px) {
 
}
